.work {
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    border: 1px solid #444;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
}

.work a {
    margin-right: 4px;
    text-decoration: none;
    color: black;
}

.work a:hover {
    text-decoration: underline;
}

.work span {
    text-overflow: ellipsis;
}

.work.CM {
    background-color: #ffd;
}

.work.TRSR {
    background-color: #efe;
}

.work.AC {
    background-color: #eef;
}

.work.NU {
    background-color: #fdf;
}

.work.tech {
    background-color: #ccc !important;
}

.work.Roadmap {
    background-color: #fcc !important;
}

.work {
    opacity: 1.0;
}

.work.InTest {
    color: transparent;
    opacity: 0.8;
    border-style: dotted;
    height: 35px;
    margin-top: 2.5px;
}

.work.InTest a {
    color: transparent;
}

.work.InTest.directToTest a {
    color: black !important;
}

.teamwork .date.weekday5 {
    border-right: 1px solid #ccc;
}

.teamwork .date {
    position: absolute;
    text-align: center;
    border-right: 1px dotted #ccc;
    height: 100vh;
}