div.bet, div.project {
  padding: 4px;
  cursor: pointer;
}

div.bet:hover, div.project:hover {
  color: #eee;
  background-color: #aaa;
}

div.bet .key, div.project .key {
  display: inline-block;
  width: 5em;
  color: #444;
  text-decoration: none;
}

div.bet .key:hover, div.project .key:hover {
  text-decoration: underline;
}

div.bet.Released span::before {
  content: 'Released';
  color: #040;
  background-color: #afa;
  border: 1px solid #040;
  margin-right: 8px;
  padding-left: 4px;
  padding-right: 4px;
  width: 6em;
  display: inline-block;
  text-align: center;
}

div.bet.In-Progress span::before {
  content: 'In Progress';
  color: #220;
  background-color: #dda;
  border: 1px solid #220;
  margin-right: 8px;
  padding-left: 4px;
  padding-right: 4px;
  width: 6em;
  display: inline-block;
  text-align: center;
}

div.bet.Research span::before {
  content: 'Research';
  color: #004;
  background-color: #aaf;
  border: 1px solid #004;
  margin-right: 8px;
  padding-left: 4px;
  padding-right: 4px;
  width: 6em;
  display: inline-block;
  text-align: center;
}

div.bet.To-review span::before {
  content: 'To Review';
  color: #022;
  background-color: #add;
  border: 1px solid #022;
  margin-right: 8px;
  padding-left: 4px;
  padding-right: 4px;
  width: 6em;
  display: inline-block;
  text-align: center;
}

div.bet.More-information-needed span::before {
  content: 'Info Needed';
  color: #400;
  background-color: #faa;
  border: 1px solid #400;
  margin-right: 8px;
  padding-left: 4px;
  padding-right: 4px;
  width: 6em;
  display: inline-block;
  text-align: center;
}

div.bet.Backlog span::before {
  content: 'Backlog';
  color: #111;
  background-color: #ccc;
  border: 1px solid #111;
  margin-right: 8px;
  padding-left: 4px;
  padding-right: 4px;
  width: 6em;
  display: inline-block;
  text-align: center;
}

div.team:hover {
  text-decoration: underline;
  cursor: pointer;
}