h1 {
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
  padding-bottom: 8px;
}

h1 .back {
  color: #aaa;
  margin-right: 8px;
  text-decoration: none;
}

h1 .back:hover {
  color: #444;
}

h1 .key {
  display: inline-block;
  color: #444;
  text-decoration: none;
}

h1 .save-span {
  color: #888;
  font-style: italic;
  font-size: 60%;
  vertical-align:top;
  font-weight: normal;
}

h1 .save-spinner {
  display: inline-block !important;
  margin-left: 0.5em;
}

div.epic-container.dragging {
  cursor: grab;
}

div.epic-container.dragging div.story, div.epic-container.dragging div.epic {
  cursor: grab !important;
}

h1 .key:hover {
  text-decoration: underline;
}

div.epic, div.story {
  padding: 8px;
  border: 2px solid #ddd;
  box-shadow: 3px 3px #eee;
  max-width: 1000px;
  cursor: pointer;
  margin-bottom: 8px;
}

div.story {
  margin-left: 2em;
  margin-right: 1em;
  max-width: calc(1000px - 3em);
  background-color: #ffe;
}
  
div.epic:hover, div.story:hover {
  background-color: #ddd;
}

div.epic .key, div.story .key {
  display: inline-block;
  width: 6em;
  color: #444;
  text-decoration: none;
}

div.epic .key:hover, div.story .key:hover {
  text-decoration: underline;
}

div.epic.Released span, div.story.Released span, div.epic.Done span, div.story.Done span {
  color: #888;
}

div.epic.Released .key, div.story.Released .key, div.epic.Done .key, div.story.Done .key {
  color: #888;
}

div.epic span.summary, div.story span.summary {
    display: inline-block;
}

div.epic div.status, div.story div.status {
  float: right;
  padding-left: 4px;
  padding-right: 4px;
}

div.epic.Released div.status, div.story.Released div.status {
  color: #040;
  background-color: #cfc;
  border: 1px solid #040;
}

div.epic.Done div.status, div.story.Done div.status {
  color: #040;
  background-color: #cfc;
  border: 1px solid #040;
}

div.epic.Release-Candidate div.status, div.story.Release-Candidate div.status {
  color: #cfc;
  background-color: #040;
  border: 1px solid #cfc;
}

div.epic.Development div.status, div.story.Development div.status {
  color: #cfc;
  background-color: #040;
  border: 1px solid #cfc;
}

div.epic.In-Test div.status, div.story.In-Test div.status {
  color: #220;
  background-color: #dda;
  border: 1px solid #220;
}

div.epic.Waiting-for-Test div.status, div.story.Waiting-for-Test div.status {
  color: #220;
  background-color: #dda;
  border: 1px solid #220;
}
  
div.epic.In-Progress div.status, div.story.In-Progress div.status {
  color: #220;
  background-color: #dda;
  border: 1px solid #220;
}

div.epic.To-Do div.status, div.story.To-Do div.status {
  color: #022;
  background-color: #add;
  border: 1px solid #022;
}

div.epic.Selected-for-Development div.status, div.story.Selected-for-Development div.status {
  color: #022;
  background-color: #add;
  border: 1px solid #022;
}

div.epic.In-requirement-stage div.status, div.story.In-requirement-stage div.status {
  color: #ddd;
  background-color: #333;
  border: 1px solid #ddd;
}

div.epic.Backlog div.status, div.story.Backlog div.status {
  color: #111;
  background-color: #ccc;
  border: 1px solid #111;
}

div.epic.Won-t-do div.status, div.story.Won-t-do div.status {
  color: #400;
  background-color: #faa;
  border: 1px solid #400;
}

div.epic.Out-of-Scope div.status, div.story.Out-of-Scope div.status {
  color: #400;
  background-color: #faa;
  border: 1px solid #400;
}

div.epic.Not-prioritized div.status, div.story.Not-prioritized div.status {
  color: #400;
  background-color: #faa;
  border: 1px solid #400;
}

div.epic.new-issue div.status, div.story.new-issue div.status {
    color: #444;
    background-color: #fff;
    border: 1px solid #444;
  }

div.epic > input, div.story > input {
  font-size: 16px;
  border: 1px solid #888;
  background-color: #dff;
  margin-top: 8px;
  margin-right: 2px;
  padding: 2px;
  width: 100%;
  box-sizing: border-box;
}

div.epic > input:focus-visible, div.story > input:focus-visible {
  outline-width: 0;
}

div.epic div.grip, div.story div.grip {
  float: right;
  padding: 0;
  margin-left: 8px;
  cursor: grab;
  color: #888;
}

div.epic.drag-target, div.story.drag-target {
  border-top: 8px solid #66f;
}

div.epic.drag-target-new-parent, div.story.drag-target-new-parent {
  border-bottom: 8px solid #4bb;
}

div.epic.drag-source, div.story.drag-source {
  opacity: 50%;
  background-color: #ddf;
}

div.epic .issue-spinner {
  margin-left: 0.5em;
}

div.epic.new-issue select, div.story.new-issue select {
  font-size: 16px;
  width: 6em;
}

div.new-issue:hover {
  background-color: unset;
  cursor: unset;
}

div.story.new-issue:hover {
  background-color: #ffe;
}

div.new-issue > input {
  background-color: #efe;
}

div.story .blocked-by, div.epic .blocked-by {
  color: #b88;
  margin-right: 8px;
  font-size: 80%;
  text-decoration: none;
}

div.story .blocked-by:hover, div.epic .blocked-by:hover {
  color: #444;
  text-decoration: underline;
}

div.story .blocking, div.epic .blocking {
  color: #bb8;
  margin-right: 8px;
  font-size: 80%;
}

#dialog-background {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
}

div.transition-dialog {
  background-color: #eee;
  border: 1px solid black;
  position: absolute;
}

div.transition-dialog .status {
  padding: 2px 4px 2px 4px;
  border-bottom: 1px solid #ccc;
}

div.transition-dialog .status:hover {
  background-color: #ccc;
  cursor: pointer;
}

.new-issue .tag {
  display: inline-block;
  border: 1px solid #888;
  border-radius: 2px;
  background-color: #eee;
  margin-left: 6px;
  padding-right: 4px;
}

.new-issue .tag input {
  vertical-align: bottom;
}
